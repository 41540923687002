import React from 'react';
import ReactDOM from 'react-dom';
import { ArgoApp } from '@awsscm/argo-core';
import * as serviceWorker from './serviceWorker';
import App from './app/App';
import { BrowserRouter } from 'react-router-dom';
import '@amzn/awsui-components-react/index.min.css';
import '@amzn/awsscmui-components-react/alcimede.min.css';

ReactDOM.render(
  <div className='awsui'>
    <React.StrictMode>
      {
        // A BrowserRouter allows us to render and navigate between different pages
        // See https://reacttraining.com/react-router/web/guides/quick-start for more info
        // Setting the basename prop allows this page to be rendered from that base path.
        // This value should match the path on the homepage attribute in your package.json.
      }
      <BrowserRouter basename={'/'}>
        <ArgoApp content={<App />} />
      </BrowserRouter>
    </React.StrictMode>
  </div>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
