import React from 'react';
import USER_DISPLAY_STRINGS from '../../resources/user_display_strings';
import './css/landing.scss';
import { useRouteLoadDurationPublisher } from '@awsscm/argo-core/argo/utils/metrics/ArgoRouteLoadDurationContext';

export default function LandingPage() {
  const publishRouteLoadDuration = useRouteLoadDurationPublisher();

  publishRouteLoadDuration();

  return (
    <div className="asui-grid awsscm-ui">
      <div className="awsui-util-action-stripe-large">
        <div className="awsui-util-action-stripe-title">
          <h1>{USER_DISPLAY_STRINGS.mainTitle}</h1>
          <p>{USER_DISPLAY_STRINGS.helpText}</p>
        </div>
      </div>
    </div>
  );
}